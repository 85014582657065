import React from 'react'

function UserLoggingData() {
  return (
    <div>
      
    </div>
  )
}

export default UserLoggingData
