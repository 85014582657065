import React from 'react'

function Singin() {
  return (
    <div>
      Sigin
    </div>
  )
}

export default Singin
